define([], function () {
    'use strict';

    function init() {
        const componentsMap = {};

        function register({widgetId, component, error}) {
            componentsMap[widgetId] = {
                component,
                error
            };
        }

        function isRegistered(widgetId) {
            return !!componentsMap[widgetId];
        }

        function getComponent(widgetId) {
            return componentsMap[widgetId] && componentsMap[widgetId].component;
        }

        return {
            register,
            getComponent,
            isRegistered
        };
    }

    return {
        init
    };
});
