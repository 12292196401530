/**
 * Created by alexandreroitman on 26/09/2016.
 */
define(['lodash', 'warmupUtils/siteData/ViewPortBatchedImageLoader'], function (_, BatchedImageLoader) {
    'use strict';

    const isInFirstViewPort = image => image.viewPort.startIndex === 0;

    // "Constructor function" to mimic old ViewPortImageLoader behaviour using the new BatchedImageLoader
    function generateViewPortImageLoader(getScreenSize, scrollTop = 0, getBrowserFlags) {
        return new BatchedImageLoader(getScreenSize,
            isInFirstViewPort,
            imageData => {
                if (imageData.viewPort.isInCurrentViewPort) {
                    return BatchedImageLoader.IMMEDIATE_LOAD_RANK;
                }

                return 1;
            }, 1, scrollTop, getBrowserFlags);
    }

    return generateViewPortImageLoader;
});
