define([
    'lodash',
    'warmupUtilsLib',
    'image-client-api',
    'warmupUtils/core/ajaxLibrary',
    'warmupUtils/core/trackingReporter',
    'warmupUtils/core/shouldShowDebugInformation',
    'warmupUtils/core/indicator',
    'warmupUtils/core/compAlignmentUtils',
    'warmupUtils/core/coreTpaUtils',
    'warmupUtils/core/flatStructureUtil',
    'warmupUtils/core/languages',
    'warmupUtils/core/layoutUtils',
    'warmupUtils/core/maxScrollHandler',
    'warmupUtils/core/measurerUtils',
    'warmupUtils/core/mobxDataHandlers',
    'warmupUtils/core/multilingual',
    'warmupUtils/core/pathValidationUtil',
    'warmupUtils/core/positionAndSize',
    'warmupUtils/core/proxyLayoutRegistrar',
    'warmupUtils/core/routerUtils',
    'warmupUtils/core/SessionFreezer',
    'warmupUtils/core/SiteDataPrivates',
    'warmupUtils/core/structuresDescription',
    'warmupUtils/core/svgFeatureDetection',
    'warmupUtils/core/touchMediaZoomUtils',
    'warmupUtils/core/warmupMenuUtils',
    'warmupUtils/dal/siteDataUtils',
    'warmupUtils/dal/DALFactory',
    'warmupUtils/loggingUtils/loggingUtils',
    'warmupUtils/pointers/pointers',
    'warmupUtils/siteData/dataResolver',
    'warmupUtils/siteData/MobileDeviceAnalyzer',
    'warmupUtils/siteData/pagesUrlUtils',
    'warmupUtils/siteData/SiteData',
    'warmupUtils/siteData/layoutAPI',
    'warmupUtils/siteData/siteStructureUtils',
    'warmupUtils/wixUrlParser/wixUrlParser',
    'warmupUtils/siteData/dataRefsMap',
    'warmupUtils/tpa/tpaWarmup'
], function (_,
             warmupUtilsLib,
             imageClientLib,
             ajaxLibrary,
             trackingReporter,
             shouldShowDebugInformation,
             indicator,
             compAlignmentUtils,
             coreTpaUtils,
             flatStructureUtil,
             languages,
             layoutUtils,
             maxScrollHandler,
             measurerUtils,
             mobxDataHandlers,
             multilingual,
             pathValidationUtil,
             positionAndSize,
             proxyLayoutRegistrar,
             routerUtils,
             sessionFreezer,
             SiteDataPrivates,
             structuresDescription,
             svgFeatureDetection,
             touchMediaZoomUtils,
             warmupMenuUtils,
             siteDataUtils,
             DALFactory,
             loggingUtils,
             pointers,
             dataResolver,
             MobileDeviceAnalyzer,
             pagesUrlUtils,
             SiteData,
             layoutAPI,
             siteStructureUtils,
             wixUrlParser,
             dataRefsMap,
             tpaWarmup
) {
    'use strict';
    imageClientLib.populateGlobalFeatureSupport();

    const exports = _.assign({},
        warmupUtilsLib,
        {
            dataRefs: dataRefsMap, // used by santa-renderer to resolve the data items
            ajaxLibrary,
            trackingReporter,
            shouldShowDebugInformation,
            indicator,
            compAlignmentUtils,
            coreTpaUtils,
            DALFactory,
            dataResolver,
            flatStructureUtil,
            imageClientApi: imageClientLib,
            languages,
            layoutUtils,
            log: warmupUtilsLib.log,
            loggingUtils,
            maxScrollHandler,
            measurerUtils,
            MobileDeviceAnalyzer,
            mobxDataHandlers,
            multilingual,
            pagesUrlUtils,
            pathValidationUtil,
            pointers,
            positionAndSize,
            proxyLayoutRegistrar,
            routerUtils,
            sessionFreezer,
            SiteData,
            layoutAPI,
            SiteDataPrivates,
            siteDataUtils,
            siteStructureUtils,
            structuresDescription,
            svgFeatureDetection,
            touchMediaZoomUtils,
            warmupMenuUtils,
            wixUrlParser,
            tpaWarmup
        });

    return exports;
});
