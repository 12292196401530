define(['lodash', 'warmupUtils/loggingUtils/loggingUtils'], function (_, loggingUtils) {
    'use strict';

    const IMAGE_REF = 'image';
    const RESIZE_DELAY = 250;
    const IMMEDIATE_LOAD_RANK = -1;


    function loadImageImmediately(image) {
        const imageLoadData = image;
        const isImageRemounted = !imageLoadData.oldSrc && imageLoadData.src;

        if (!image.resizeHandler || isImageRemounted) {
            setImageSrc(imageLoadData);
            image.resizeHandler = _.debounce(setImageSrc, RESIZE_DELAY, {trailing: true});
        } else {
            image.resizeHandler(imageLoadData);
        }

        return imageLoadData.promise;
    }

    function promisedImageLoad(imageLoadData, resolve, reject) {
        const patchers = imageLoadData.patchers,
            imageId = imageLoadData.id + IMAGE_REF,
            newSrc = imageLoadData.src;

        imageLoadData.cancel = function () {
            reject({canceled: true});
        };

        if (newSrc) {
            patchers.events(imageId, {
                onload: resolve,
                onerror: reject
            });
        }

        if (imageLoadData.isSvg) {
            patchers.attrNS(imageId, [{
                ns: 'http://www.w3.org/1999/xlink',
                attribute: 'xlink:href',
                value: newSrc
            }]);
        } else {
            patchers.attr(imageId, {src: newSrc});
        }

        if (!newSrc) {
            resolve();
        }

        if (imageLoadData.isSvg && !imageLoadData.isOnLoadEventSupported) {
            resolve();
        }
    }

    function createImageLoadPromise(imageLoadingData) {
        return (new Promise(_.partial(promisedImageLoad, imageLoadingData)))
            .catch(function (event) {
                if (event && event.canceled) {
                    return Promise.resolve(event);
                }
                return Promise.reject(event);
            });
    }

    function setImageSrc(imageLoadingData) {
        const {src, oldSrc} = imageLoadingData;

        if (src !== oldSrc) {
            if (imageLoadingData.cancel) {
                imageLoadingData.cancel();
            }
            imageLoadingData.promise = createImageLoadPromise(imageLoadingData);
        } else if (!imageLoadingData.promise) {
            imageLoadingData.promise = Promise.resolve();
        }

        return imageLoadingData.promise;
    }

    function reflect(promise) {
        return new Promise(function (resolve) {
            return promise.then(resolve).catch(resolve);
        });
    }

    function rankAll(ranker, images) {
        return _.mapValues(images, image => {
            image.rank = ranker(image); // eslint-disable-line santa/no-side-effects
            return image;
        });
    }

    function isImageInViewPort(viewPortHeight, imageTop, imageHeight, scrollTop = 0) {
        const imageBottom = imageTop + imageHeight;
        const viewPortTop = scrollTop;
        const viewPortBottom = viewPortTop + viewPortHeight;

        return imageTop >= viewPortTop && imageTop <= viewPortBottom || // eslint-disable-line no-mixed-operators
            imageBottom >= viewPortTop && imageBottom <= viewPortBottom || // eslint-disable-line no-mixed-operators
            imageTop <= viewPortTop && imageBottom >= viewPortBottom; // eslint-disable-line no-mixed-operators
    }

    const calculateImageStartViewPort = (imageTop, viewPortHeight) => Math.floor(imageTop / viewPortHeight);
    const calculateImageEndViewPort = (imageTop, imageHeight, viewPortHeight) => Math.floor((imageTop + imageHeight) / viewPortHeight);

    function makeGenData(getScreenSize, scrollTop) {
        const screenSize = getScreenSize();

        return {
            viewPort: {
                index: calculateImageStartViewPort(scrollTop, screenSize.height),
                size: screenSize
            }
        };
    }

    function makeImageData(getScreenSize, scrollTop, image) {
        const viewPortHeight = getScreenSize().height;

        return {
            viewPort: {
                startIndex: calculateImageStartViewPort(image.absoluteTop, viewPortHeight),
                endIndex: calculateImageEndViewPort(image.absoluteTop, image.height, viewPortHeight),
                isInCurrentViewPort: isImageInViewPort(viewPortHeight, image.absoluteTop, image.height, scrollTop)
            },
            imageData: image
        };
    }

    function rank(imageLoader, image) {
        return imageLoader.ranker(
            makeImageData(imageLoader.getScreenSize, imageLoader.scrollTop, image),
            makeGenData(imageLoader.getScreenSize, imageLoader.scrollTop)
        );
    }

    function checkIsImageInFirstUserViewPort(imageLoader, image) {
        return imageLoader.isImageInFirstUserViewPort(makeImageData(imageLoader.getScreenSize, imageLoader.scrollTop, image));
    }

    /**
     *
     * @param getScreenSize - getScreenSize function
     * @param isImageInFirstUserViewPort - function - used for bi
     * @param rankerFunc - takes an image and returns a rank
     * @param numberOfRanksToLoad - number of batches to load, each batch containing one rank value
     * @param scrollTop
     * @constructor
     */
    function ViewPortBatchedImageLoader(getScreenSize, isImageInFirstUserViewPort, rankerFunc, numberOfRanksToLoad, scrollTop = 0, getBrowserFlags) {
        this.nextId = 0;
        this.images = {};
        this.numberOfRanksToLoad = numberOfRanksToLoad;
        this.scrollTop = scrollTop;
        this.getScreenSize = getScreenSize;
        this.ranker = rankerFunc;
        this.isImageInFirstUserViewPort = isImageInFirstUserViewPort;
        this.isOnLoadEventSupported = () => getBrowserFlags().svgImageOnLoadEvent;
    }

    ViewPortBatchedImageLoader.prototype = {
        loadImage(image) {
            const id = image.id;

            if (!image.src && !image.currentSrc) {
                return;
            }
            const isOnLoadEventSupported = this.isOnLoadEventSupported();
            this.images[id] = _(this.images[id])
                .assign(_.pick(image, ['src', 'isSvg', 'id', 'patchers', 'height', 'absoluteTop']))
                .assign({
                    oldSrc: image.currentSrc,
                    rank: rank(this, image),
                    isOnLoadEventSupported
                })
                .value();

            if (this.images[id].rank === IMMEDIATE_LOAD_RANK) {
                this.images[id].promise = loadImageImmediately(this.images[id]);
            }
        },

        removeImage(id) {
            const imageLoadingData = this.images[id];

            if (imageLoadingData) {
                if (imageLoadingData.cancel) {
                    imageLoadingData.cancel();
                }

                // Cancel possibly debounced function
                if (imageLoadingData.resizeHandler) {
                    imageLoadingData.resizeHandler.cancel();
                }

                delete this.images[id];
            }
        },

        loadAllImages(siteData) {
            loggingUtils.imagesBi(
                siteData,
                _.filter(this.images, _.partial(checkIsImageInFirstUserViewPort, this))
            );

            this.lastReRankId = this.getNextRankingId();

            return Promise.all(
                _(this.images)
                    .filter(['rank', IMMEDIATE_LOAD_RANK])
                    .map('promise')
                    .map(reflect)
                    .value()
            ).then(this.loadNextBatch.bind(this, this.numberOfRanksToLoad, this.lastReRankId, -1));
        },

        loadNextBatch(n, currReRankId, lastRank) {
            const imagesNotLoadedYet = _.filter(this.images, image => image.rank > lastRank);

            if (_.size(imagesNotLoadedYet) === 0 || n <= 0) {
                return Promise.resolve();
            }

            const minRank = Math.min.apply(
                null,
                _(imagesNotLoadedYet)
                    .values()
                    .map('rank')
                    .value()
            );

            return Promise.all(_(imagesNotLoadedYet)
                .values()
                .filter(['rank', minRank])
                .map(loadImageImmediately)
                .map(reflect)
                .value())
                .then(() => {
                    if (currReRankId === this.lastReRankId) {
                        return this.loadNextBatch(n - 1, currReRankId, minRank);
                    }
                });
        },

        getNextRankingId() {
            return this.nextId++;
        },

        forceReRank(siteData, ranker, scrollTop) {
            this.ranker = ranker;

            if (scrollTop !== undefined) {
                this.scrollTop = scrollTop;
            }

            this.images = rankAll(_.partial(rank, this), this.images);
            return this.loadAllImages(siteData);
        }
    };

    Object.defineProperty(ViewPortBatchedImageLoader, 'IMMEDIATE_LOAD_RANK', {
        value: IMMEDIATE_LOAD_RANK,
        writable: false
    });

    return ViewPortBatchedImageLoader;
});
