define(['lodash', 'warmupUtils/loggingUtils/loggingUtils'], function (_, loggingUtils) {
    'use strict';

    function BiData(wixBiSession, getCurrentUrlPageId) {
        this.getCurrentUrlPageId = getCurrentUrlPageId;
        this.pageNumber = 1;
        this.pageLoadStart = wixBiSession && wixBiSession.initialTimestamp || _.now(); // eslint-disable-line no-mixed-operators
        this.wixBiSession = wixBiSession;
    }

    BiData.prototype = {
        getPageId() {
            return this.pageId || this.getCurrentUrlPageId();
        },
        getPageNumber() {
            return this.pageNumber;
        },
        getTime() {
            const loadingTime = _.now() - this.pageLoadStart;
            const totalLoadingTime = this.pageNumber === 1 ? Math.round(loggingUtils.performance.now()) : loadingTime;
            return {
                loadingTime,
                totalLoadingTime
            };
        },
        setPage(pageId) {
            this.pageId = pageId;
        },
        switchPage(pageId) {
            this.setPage(pageId);
            this.wixBiSession.pn = ++this.pageNumber;
            this.pageLoadStart = _.now();
        }
    };

    return BiData;
});
